<template>
    <div class="wrapper-box d-flex">
        <div class="wrapper-info">
            <div v-if="!setCloseToBottom" class="header d-flex">
                <h3 v-if="!setCloseToBottom" class="title flex-grow-1 m-0">
                    {{ title ? title : $t('itemInfoTitle') }}
                </h3>
                <slot name="button"></slot>
            </div>
            <div
                class="content d-flex overflow-auto"
                :style="isIframe && { height: '400px' }"
            >
                <slot name="content"></slot>
            </div>
            <div
                v-if="setCloseToBottom"
                class="header d-flex justify-content-end"
            >
                <slot name="button"></slot>
            </div>
        </div>
        <div class="mt-3 d-inline-flex justify-content-end">
            <slot name="bottom-button"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxWrapper',
    props: {
        title: {
            type: String,
            required: false,
            default: () => null,
        },
        isIframe: {
            type: Boolean,
            default: false,
        },
        setCloseToBottom: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper-box {
    .wrapper-info {
        background-color: var(--lightgrey);
        width: calc(548vw * var(--pxToVw));
        //max-height: 500px;
        text-align: left;
        overflow-y: hidden;
    }

    .header {
        .title {
            text-transform: uppercase;
            background-color: var(--mediumgrey);
            border: calc(3vw * var(--pxToVw)) solid var(--secondary);
            text-align: left;
            padding-left: calc(8vw * var(--pxToVw));
        }
    }
}
</style>
