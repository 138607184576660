import { render, staticRenderFns } from "./BoxWrapper.vue?vue&type=template&id=092e4344&scoped=true"
import script from "./BoxWrapper.vue?vue&type=script&lang=js"
export * from "./BoxWrapper.vue?vue&type=script&lang=js"
import style0 from "./BoxWrapper.vue?vue&type=style&index=0&id=092e4344&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092e4344",
  null
  
)

export default component.exports