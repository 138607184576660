const randomNumber = (min, max) => {
    min = Math.ceil(min)
    max = Math.ceil(max)
    return Math.floor(Math.random() * (max - min)) + min
}
const mapNumber = (num, inMin, inMax, outMin, outMax) => {
    return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

const getRandomPositions = (
    minX,
    maxX,
    minY,
    maxY,
    diameter,
    count,
    excludedRects,
) => {
    const positions = []
    let counter = 0
    let counter2 = 0
    while (positions.length < count) {
        counter++
        const x = randomNumber(minX, maxX)
        const y = randomNumber(minY, maxY)
        let overlapping = false
        let notWihtinExcludedRects = true

        excludedRects.forEach(rect => {
            if (
                x > rect.x &&
                x < rect.x + rect.width &&
                y > rect.y &&
                y < rect.y + rect.height
            ) {
                notWihtinExcludedRects = false
            }
        })

        if (notWihtinExcludedRects) {
            for (let i = 0; i < positions.length; i++) {
                let position = positions[i]
                let distance = Math.hypot(x - position.x, y - position.y)
                /*
                Math.sqrt(
                Math.pow(x - position.x, 2) + Math.pow(y - position.y, 2),
            )*/
                if (distance < diameter) {
                    overlapping = true
                    break
                }
            }
        }

        if ((!overlapping && notWihtinExcludedRects) || counter > 100000) {
            positions.push({ x, y })
        }
    }
    return positions
}

const getDistance = (pointA, pointB) => {
    return Math.sqrt(
        Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2),
    )
}
const getCenterOfElement = element => {
    const rect = element.getBoundingClientRect()
    return {
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
    }
}

export {
    mapNumber,
    randomNumber,
    getRandomPositions,
    getDistance,
    getCenterOfElement,
}
